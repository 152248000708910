import React from "react";
import NavbarHeader from "../Navbar";
import style from "./accountStyle.module.css";
import HeaderTwo from "../../pages/HeaderTwo";
import FooterBfsg from "../FooterBfsg";
import {
  COntactName,
  ChooseBrand,
  QuestionMark,
  Picture,
  StoreName,
  Describe,
  Gmail,
} from "./svgIcon";

function CreateAccount() {
  return (
    <div>
      <section>
        {/* <HeaderTwo /> */}
        <NavbarHeader />
        <div className="container">
          <div className={style.WholesaleFormMain}>
            <h3>Apply for a Wholesale Account with One of Our Brands!</h3>
            <br></br>
            {/* <div className={style.accountCre}>
              <h5>Advantages of Account Creation :</h5>
              <ul>
                <li>Express Checkout</li>
                <li>Monitor Your Orders</li>
                <li>Add favorite items to your wish list</li>
              </ul>
            </div> */}

            <div className={style.detailFilling}>
              <div className={style.innerInformation}>
                <div className={style.SvgLogo}>
                  <COntactName />
                </div>
                <div className={style.labelDivMain}>
                  <div className={style.labelIN}>
                    <label htmlFor="name">First Name</label>
                    <br />
                    <input type="text" />
                  </div>

                  <div className={style.labelIN}>
                    <label htmlFor="name">Last Name</label>
                    <br />
                    <input type="text" />
                  </div>
                </div>
              </div>

              <div className={style.innerInformation}>
                <div className={style.SvgLogo}>
                  <Gmail />
                </div>
                <div className={style.labelDivMain}>
                  <div className={style.labelIN}>
                    <label htmlFor="name">Your Email</label>
                    <br />
                    <input type="text" />
                  </div>

                  <div className={style.labelIN}>
                    <label htmlFor="name">Phone Number</label>
                    <br />
                    <input type="text" />
                  </div>
                </div>
              </div>

              <div className={style.innerInformation}>
                <div className={style.SvgLogo}>
                  <StoreName />
                </div>
                <div className={style.labelDivMain}>
                  <div className={style.labelIN}>
                    <label htmlFor="name">Store Name</label>
                    <br />
                    <input type="text" />
                  </div>

                  <div className={style.labelIN}>
                    <label htmlFor="name">Store Location</label>
                    <br />
                    <input type="text" />
                  </div>
                </div>
              </div>

              <div className={style.innerInformation}>
                <div className={style.SvgLogo}>
                  <Describe />
                </div>
                <div className={style.labelDivMain}>
                  <div className={style.labelIN}>
                    <label htmlFor="name">
                      Describe your Store and target customer
                    </label>
                    <br />
                    <input className="w-95" type="text" />
                  </div>
                </div>
              </div>

              <div className={style.innerInformation}>
                <div className={style.SvgLogo}>
                  <Picture />
                </div>
                <div className={style.labelDivMain}>
                  <div className={style.labelIN}>
                    <label htmlFor="name">
                      Picture (at least 1 exterior, 1-2 of the sales floor, and
                      1-2 of the proposed merchandising location)
                    </label>
                    <br />
                    <input className="w-95" type="text" />
                  </div>
                </div>
              </div>

              <div className={style.innerInformationRadio}>
                <div className={`${style.SvgLogo} ${style.SvgLogo1}`}>
                  <QuestionMark />
                </div>

                <div className={style.LabelContro}>
                  <div>
                    <h3>How do you sell to your customers? </h3>
                  </div>

                  <div className={style.radioContro}>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div
                          className={` ${style.labelDetail} ${style.labelbox} `}
                        >
                          <label>
                            <input type="radio" /> Local Ads
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div
                          className={` ${style.labelDetail} ${style.labelbox} `}
                        >
                          <label>
                            <input type="radio" /> Social Media
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div
                          className={` ${style.labelDetail} ${style.labelbox} `}
                        >
                          <label>
                            <input type="radio" /> Google
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div
                          className={` ${style.labelDetail} ${style.labelbox} `}
                        >
                          <label>
                            <input type="radio" /> walk-in customer
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div
                          className={` ${style.labelDetail} ${style.labelbox} `}
                        >
                          <label>
                            <input type="radio" /> One to One
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div
                          className={` ${style.labelDetail} ${style.labelbox} `}
                        >
                          <label>
                            <input type="radio" /> Beauty Demoes
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div
                          className={` ${style.labelDetail} ${style.labelbox} `}
                        >
                          <label>
                            <input type="radio" /> Ecommerce Site
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.innerInformationRadio}>
                <div className={`${style.SvgLogo} ${style.SvgLogo2}`}>
                  <ChooseBrand />
                </div>

                <div className={style.LabelContro}>
                  <div>
                    <h3>Choose Brands to Apply </h3>
                  </div>

                  <div className={style.radioContro}>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Estee Lauder
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Maison Margiela
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> BUXOM
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Laura Mercier
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Bare Minerals
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Supergoop!
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> L’Occitane
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Victoria Beckham Fragrance
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Victoria Beckham Beauty
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Smashbox
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Byredo
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Diptyque
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Bobbi Brown
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> RMS Beauty
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> ReVive
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Kevyn Aucoin Cosmetics
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> NEST New York
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> Bumble and Bumble
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className={style.labelDetail}>
                          <label>
                            <input type="radio" /> By Terry
                          </label>
                        </div>
                      </div>
                      <div>
                        <h3>
                          Please list the other top brands you carry, with
                          emphasis on brands in a similar category (i.e. other
                          fragrance, makeup, skincare, or haircare brands).{" "}
                        </h3>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <textarea
                          id="w3review"
                          name="w3review"
                          rows="4"
                          cols="50"
                          style={{ width: "100%" }}
                        ></textarea>
                      </div>
                      <div className={style.BySigning}>
                        <p>
                          <input type="checkbox" />
                          By signing in or clicking "Apply for an Account", you
                          agree to our Terms of Service Please also read our
                          Privacy Policy
                        </p>

                        <button>Apply Now</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterBfsg />
      </section>
    </div>
  );
}

export default CreateAccount;
